@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/mixins/_breakpoints.scss";

$ddb-orange: orange;

#main-container {
  padding-top: 10px;
  padding-right: 15px;
}

#photo-preview {
  width: 100%;
}

.bsd-page-title {
  padding-bottom: 10px;
}

.clickable-row {
  cursor: pointer;
}

.prof-attrib {
  padding-top: 20px;
}

.actions {
  padding-bottom: 10px;
}

.profile-body {
  padding-top: 10px;
}

.reupload {
  margin-top: 10px;
}

.vspace {
  margin-top: 30px;
}

.devinfo {
  background-color: #ffc3b3;
  border: #ff4141 2px solid;
}

img {
  image-orientation: from-image;
}

#ddb-logo {
  height: 50px;
}

.note-border {
  border-left: 2px solid black;
  padding-left: 10px;
  height: 100%;
  margin-bottom: 250px;
}


@include media-breakpoint-down(md) {

  .nav-link {
    font-size: 2em;
    border-top: 1px solid grey;
  }
  #ddb-logo {
    height: 120px;
  }
  html {
    font-size: 2rem;
  }
  .full-btn {
    width: 100%;
  }
  .note-border{
    border-left: 0px;
    padding-left: 0px;
  }
  .note-col {
    padding-bottom: 200px;
  }
  .edit-note {
    margin-bottom: 20px;
  }
  .full {
    width: 100%;
    margin-bottom: 20px;
  }
}

.button-row {
  align-items: center;
  margin-bottom: 10px;

  .form-control {
    margin-left: 10px;
  }
}

.button-row button {
  margin-left: 10px;
}
#buttonid {
  margin-left: auto;
  flex-direction: row-reverse;
}

.buttonid {
  margin-left: auto;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.navbar {
  .btn {
    margin-left: 10px;
  }
}


.form-group {
  align-items: center;

  label {
    text-align: right;
    margin-bottom: 0;
  }
}

.image-group {
  align-items: center;
}

.remove-button {
  margin-top: 10px;
}

.grid-tooltip {
  padding: 10px;
  opacity: .85;
  div ul {
    margin-bottom: 0px;
  }
}

.grid-capture {
  position: absolute;
  height: 112px;
  width: 532px;

}

.grid-to-align {
  //text-align: center;
}

.grid-class {
  padding: 0px;
}

.site-error {
  margin-top: 25px;
}

.image-row {
  margin: 40px;
}

.client-search {
  margin-right: 5px;
  margin-left: 5px;
}

.pointer {
  cursor: pointer;
}

.grid-master {
  margin: 20px;
  margin-top: 0px;
}

.ban-radio-row {
  display: flex;
  align-items: center;

  .form-check-input {
    margin-top: 0px;
  }

  line-height: 36px;
}

.ban-label {
  margin-top: 6px;
}

.banned-error {
  background-color: red;
  padding: 10px 20px 10px 20px;
  font-size: 24px;
  .details-link {
    color: rgb(255, 222, 0)
  }
  .details-link:hover {
    cursor: pointer;
    color: rgb(255, 222, 0);
    text-decoration: underline;
  }
}

.error-background {
  background-color: rgba(255, 0, 0, .25);
}

.table-striped {
  cursor: pointer;
}
.table-striped tbody {
  tr.banned-row:nth-of-type(2n) {
    background-color: rgba(255, 0, 0, .25);
  }
  tr.banned-row:nth-of-type(2n+1) {
    background-color: rgba(255, 0, 0, 0.5);
  }
  tr.banned-row:hover {
    background-color: rgba(255, 0, 0, .65);
  }
}

div.text-editor {
  font-family: inherit;
  width: 100%;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;

  input {
    width: 100%;
  }
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  width: 100%;

  z-index: 20;
  div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #cccccc;
  }

  div:hover {
    background-color: #e4606d;
  }
}

.autocomplete-item {

  .client-name {
    border: 0px;
    font-size: 22px;
    display:  inline;
    background-color: rgba(0, 0, 0, 0);
  }
}

.tall-chart {
  height: 300px;
}

.chart {
  margin-bottom: 30px;
}

.expander {
  width: 100%;
  cursor: pointer;
  margin-bottom: 50px;
  padding-left: 75px;
}

.chart-group {
  border: 1px solid #d0bcac;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;
}

.divider {
  border-bottom: 2px solid;
}



.mobile-img {
  padding: 50px;
  width: 50%;
}

input.form-control-xl {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 4rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.form-group-mobile {
  font-size: 2rem;
  padding: 50px;
}

button.btn-mobile {
  font-size: 4rem;
  line-height: 2;
}

.profile-side {
  margin-bottom: 20px;
}

.embedded-client-page {
  font-size: .8em;
}