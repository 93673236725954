.center {
  margin: 0 auto;
  width: 100px; }

.bsd-loader {
  height: 80px;
  width: 100px; }

.bsd-loader div {
  float: left;
  margin-right: 12px;
  left: 8px;
  width: 16px;
  background: #f15a29aa;
  animation: bsd-loader 1s cubic-bezier(0, 0.5, 0.5, 1) infinite; }

.bsd-loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s; }

.bsd-loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s; }

.bsd-loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0s; }

@keyframes bsd-loader {
  0% {
    top: 8px;
    margin-top: 8px;
    height: 64px; }
  50%, 100% {
    top: 24px;
    margin-top: 24px;
    height: 32px; } }
